import React from "react";
import { Link } from "react-router-dom";
import ContactSection from "./ContactSection";

const ContactHero = () => {
  return (
    <section className="py-10 bg-gradient-to-r from-blue-600 to-teal-500 text-white">
        <div className="container mx-auto px-6 lg:px-12">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center">
            <div data-aos="fade-right">
              <h2 className="text-4xl font-bold">Let's Chat Over a Brew</h2>
              <p className="mt-4 opacity-90">Coffee, tea, or something stronger...</p>
            </div>
           <ContactSection/>
          </div>
        </div>
      </section>
  );
};

export default ContactHero;
{
  /* <div className="bg-cover bg-center h-64 flex flex-col items-center justify-center text-white" style={{ backgroundImage: "url('../contact_bg.webp')" }}>
</div> */
}
