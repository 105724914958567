import React from "react";
import ServiceHero from "../component/ServiceHero";
import Technologies from "../component/Technologies";
import ContactHero from "../component/ContactHero";
import { Website } from "../component/Helper";
import Ourwork from "../component/Ourwork";
const ItManagement = () => {

  return (
    <div>
      <ServiceHero
        backgroundImage="../data-tracking-bg.png"
        heading="IT Management"
      />
      <div class="max-w-6xl mx-auto my-12 px-6">
        <div class="container mx-auto px-4 py-8">
          <h1 class="text-4xl font-bold text-center text-blue-600 mb-6">IT Management Services at Codecups</h1>
          <h2 class="text-2xl font-semibold text-gray-700 mb-4 text-center">Your Trusted IT Management Agency</h2>
          <p class="text-lg text-gray-600 leading-relaxed mb-6 text-center">
            At Codecups, our <span class="font-semibold text-blue-500">IT management services</span> simplify technology for businesses of all sizes. From <span class="font-semibold text-blue-500">IT infrastructure</span> to <span class="font-semibold text-blue-500">cloud management</span>, we provide solutions that are secure, reliable, and designed for growth. Let us help you focus on what matters most—your business.
          </p>

          <h3 class="text-xl font-bold text-gray-800 mb-4">Why Choose Codecups for IT Management?</h3>
          <p class="text-lg text-gray-600 mb-6">
            We are more than just an <span class="font-semibold text-blue-500">IT management agency</span>. We are your partner in success. From proactive monitoring to customized solutions, we help businesses improve efficiency, reduce costs, and drive results.
          </p>

          <h3 class="text-xl font-bold text-gray-800 mb-4">Our Expertise</h3>
          <ul class="list-disc list-inside space-y-4 mb-6">
            <li>
              <strong class="text-blue-500">IT Infrastructure Management</strong>:
              We manage your <span class="font-semibold">hardware, software, networks,</span> and <span class="font-semibold">servers</span> to ensure <span class="font-semibold">optimal performance</span>, uptime, and security.
            </li>
            <li>
              <strong class="text-blue-500">Network Management</strong>:
              Our team handles <span class="font-semibold">LAN</span>, <span class="font-semibold">WAN</span>, <span class="font-semibold">VPNs</span>, and <span class="font-semibold">wireless networks</span>, ensuring seamless connectivity and secure data transfer.
            </li>
            <li>
              <strong class="text-blue-500">Help Desk and Technical Support</strong>:
              Get quick and reliable <span class="font-semibold">end-user support</span> for basic queries and advanced technical issues with our multi-tiered help desk services.
            </li>
            <li>
              <strong class="text-blue-500">Cybersecurity Management</strong>:
              Protect your business with <span class="font-semibold">firewalls</span>, <span class="font-semibold">antivirus solutions</span>, and <span class="font-semibold">intrusion detection systems</span> to safeguard against threats.
            </li>
            <li>
              <strong class="text-blue-500">Cloud Management Services</strong>:
              Optimize your <span class="font-semibold">cloud infrastructure</span> (e.g., AWS, Azure, Google Cloud) for <span class="font-semibold">cost-efficiency</span>, scalability, and security.
            </li>
          </ul>

          <h3 class="text-xl font-bold text-gray-800 mb-4">Why Codecups Stands Out</h3>
          <ul class="list-disc list-inside space-y-4">
            <li>
              Proven expertise in <span class="font-semibold text-blue-500">IT management services</span> for businesses of all sizes.
            </li>
            <li>
              A trusted partner for <span class="font-semibold text-blue-500">secure, reliable</span>, and <span class="font-semibold text-blue-500">growth-focused IT solutions</span>.
            </li>
            <li>
              Dedicated to delivering innovative <span class="font-semibold text-blue-500">IT management services</span> that simplify your technology landscape.
            </li>
          </ul>
        </div>
      </div>


      {/* <Sdlc /> */}
      <Technologies tabs={Website} />
      <ContactHero />
      <Ourwork />
    </div>
  );
};

export default ItManagement;
