import React from "react";
import Marquee from "react-fast-marquee";
import CounterSection from "../component/Counter";
import HeroSection from "../component/HeroSection";
import { boxes, clientLogos } from "../component/Helper";
import { Link } from "react-router-dom";
import Ourwork from "../component/Ourwork";

const Home = () => {
  return (
    <div className=" bg-white overflow-x-hidden">
      <div className="container  overflow-hidden">
        <div
          className="  md:px-4 flex md:mt-7 lg:flex-row flex-col bg-white   lg:pt-[5px]">
          <div className=" px-[16px] py-[25px] lg:w-4/5 w-full md:rounded-xl lg:px-[100px]  bg-[#e3f0ff] lg:py-[80px] ">
            <p className=" text-black text-sm mb-4 mt-4 " data-aos="fade-right">
              ✈️ Elevate Your Business with
              <span className="bg-[#f44e4ee2] text-white px-1 ms-1 font-medium rounded-sm">
                CodeCups
              </span>
            </p>
            <h1
              className=" text-[#0b0b2b]  font-bold text-3xl lg:text-5xl lg:pr-[10px] "
              data-aos="fade-right"
            >
              Grow Your Business Potential with Smart IT Solutions
            </h1>
            <p className=" mt-5 text-md text-[#4b4a4bee] font-medium ">
              In today’s fast-paced world, your success relies on effective and
              affordable IT strategies. Let us help your business grow and
              shine!
            </p>
            <div className="w-fit font-semibold mt-6 px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all">
              <Link to="/contact">
                Get Started Today
              </Link>
            </div>
          </div>
          <HeroSection data-aos="fade-left" />
        </div>

        {/* //////////////////////Section 4/\\\\\\\\\\\\\\\\\\\\\\\ */}
        <div className="bg-white mt-10 " data-aos="fade-up">
          <div className="grid  grid-cols-1 lg:grid-cols-2  lg:gap-10 px-3 md:px-8">
            <div className=" relative">
              <div className="absolute top-10 left-[5%]">
                <img
                  className="world_animate  md:w-[80px] w-[50px] "
                  src="./world.webp"
                  alt="world"
                />
              </div>
              <img
                className=" ml-[-20px] md:ml-0"
                src="./Section4homepage.webp"
                alt="Section4homepage"
                data-aos="fade-up"
              />
            </div>
            <div className="lg:ps-10 pt-4">
              {/* <h3 className="text-black ps-2 text-sm" data-aos="fade-left">
                We are 😃
                <span className="bg-[#f44e4ee2] text-white px-1 font-medium rounded-sm">
                  CodeCups
                </span>
              </h3> */}
              <h2
                className=" tracking-tighter lg:text-4xl text-2xl pt-1 text-[#020842] font-semibold"
                data-aos="fade-left"
              >
                Our Commitment to Client Satisfaction
              </h2>
              <p className=" text-[#383838e6] lg:pe-10 md:pt-5 pt-3 md:text-md text-md font-semibold">
                At CodeCups, our commitment to client satisfaction is at the
                core of everything we do. We understand clients' success.
              </p>
              <ul className=" ps-5 pt-5 mb-6">
                <li className="list-disc text-[#383838e6] text-sm md:text-md font-medium ">
                  Grow your business the right way.
                </li>
                <li className="list-disc py-2 sm:py-3 text-[#383838e6] text-sm md:text-md font-medium ">
                  Let business growth help your business grow.
                </li>
                <li className="list-disc text-[#383838e6] text-sm md:text-md font-medium ">
                  Helping you to get better.
                </li>
              </ul>
              <div className="w-fit mt-6 px-8 py-3 font-semibold bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all">
                <Link to="/contact" data-aos="fade-right">
                  Work With Us
                </Link>
              </div>
            </div>
          </div>
          <div className="md:px-10 md:py-5 mt-2 px-3">
            <div className="grid pt-10 grid-cols-1 lg:grid-cols-3  lg:gap-10">
              <div
                className="flex  items-center mb-7 md:mb-4"
                data-aos="zoom-in"
              >
                <div className=" bg-[#f3baba6c] p-5 rounded-lg">
                  <img
                    width={30}
                    className=" "
                    src="./usericonsection4.svg"
                    alt="usericonsection4"
                  />
                </div>
                <div className="ps-4">
                  <p className=" text-md lg:text-xl text-black font-medium ">
                    Expert Team Members
                  </p>
                  <p className=" text-[#474646] font-normal mt-1 md:text-md text-sm">
                    We take pride in assembling a diverse and highly skilled.
                  </p>
                </div>
              </div>
              <div
                className="flex  items-center mb-7 md:mb-4"
                data-aos="zoom-in"
              >
                <div className=" bg-[#f3baba6c] p-5 rounded-lg">
                  <img
                    width={40}
                    className=" "
                    src="./headphonesection4.svg"
                    alt="headphonesection4"
                  />
                </div>
                <div className="ps-4">
                  <p className=" text-md lg:text-xl text-black font-medium ">
                    Fastest Customer Service
                  </p>
                  <p className=" text-[#474646] font-normal mt-1 md:text-md text-sm">
                    We pride ourselves on providing the fastest customer service
                    industry.
                  </p>
                </div>
              </div>
              <div
                className="flex  items-center mb-7 md:mb-4"
                data-aos="zoom-in"
              >
                <div className=" bg-[#f3baba6c] p-5 rounded-lg">
                  <img
                    width={40}
                    className=" "
                    src="./dollarsection4.svg"
                    alt="usericonsection4"
                  />
                </div>
                <div className="ps-4">
                  <p className=" text-md lg:text-xl text-black font-medium ">
                    Reasonable Pricing
                  </p>
                  <p className=" text-[#474646] font-normal mt-1 md:text-md text-sm">
                    We believe in providing reasonable pricing that offers
                    exceptional.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* /////////////// sction5  Our Specialize\\\\\\\\\\\\\\\\\\\ */}
          <div className="py-10">
            <div className=" min-container ">
              <h3 className="text-black lg:pt-10 ps-2 text-sm text-center">
                Our
                <span className="bg-[#f44e4ee2] text-white px-1 ms-1 font-medium rounded-sm">
                  Specialize
                </span>
              </h3>
              <h2 className=" tracking-tighter lg:text-4xl text-2xl pt-1 text-[#020842] font-semibold text-center">
                Featured Services
              </h2>
              <div className="grid mt-5 md:mt-10 gap-4">
                {/* First Row: 2 Boxes */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                  {boxes.slice(0, 2).map((box) => (
                    <div
                      key={box.id}
                      className="relative pt-[250px] p-4 py-10 rounded-xl shadow overflow-hidden transform transition-transform duration-300 hover:scale-[.98] group"
                    >
                      <Link to={box.url}>
                        <div
                          style={{
                            backgroundImage: `url(${box.imageUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          className="absolute  inset-0 transform transition-transform duration-300 group-hover:scale-110"
                          data-aos="fade-up"
                        />

                        <div className="relative z-10 text-white text-2xl md:text-4xl px-4 font-semibold">
                          <h4 className="mt-10 md:pt-10 tracking-tighter lg:text-3xl text-2xl pt-1  font-semibold">
                            {box.content} Services
                          </h4>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>

                {/* Second Row: 3 Boxes */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-4">
                  {boxes.slice(2).map((box) => (
                    <div
                      key={box.id}
                      className="relative pt-[250px] p-4 py-10 rounded-xl shadow overflow-hidden transform transition-transform duration-300 hover:scale-[.98] group"
                    >
                      <Link to={box.url}>
                        <div
                          style={{
                            backgroundImage: `url(${box.imageUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          data-aos="fade-up"
                          className="absolute inset-0 transform transition-transform duration-300 group-hover:scale-110"
                        />
                        <div className="relative z-10 text-white text-2xl md:text-4xl px-4 font-semibold">
                          <h4 className="mt-10 md:pt-10 tracking-tighter lg:text-3xl text-2xl pt-1  font-semibold">
                            {box.content} Services
                          </h4>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/*clutch review*/}

          <div class="mt-8 flex flex-col items-center text-center bg-gray-50 py-6 rounded-lg shadow-md">
            <div class="mb-4 bg-white">
              <img src="./clutch.png" alt="Clutch Logo" class="h-12" />
            </div>
            <p class="text-lg font-semibold text-gray-800">
              Rated <span class="text-blue-600">4.8/5</span> by 28+ Reviews on
              Clutch
            </p>
            <div class="flex mt-3 space-x-1 ">
              <img src="./start.svg" width="25" alt="rating start" />
              <img src="./start.svg" width="25" alt="rating start" />
              <img src="./start.svg" width="25" alt="rating start" />
              <img src="./start.svg" width="25" alt="rating start" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-gray-300"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
              </svg>
            </div>
            <p class="mt-4 text-sm text-gray-600">
              "CodeCups helped our business achieve new heights. Highly
              recommend their services!"
            </p>
            <Link
              to="https://clutch.co"
              target="_blank"
              className="w-fit font-semibold mt-6 px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all"
            >
              Read All Reviews
            </Link>
          </div>

          <Ourwork />
          {/* ///////////////////// Section 5\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
