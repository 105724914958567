// ContactSection.js
import React from 'react';

const ContactSection = () => {
  return (
    <div className="text-white px-6">      
      <form className="max-w-2xl mx-auto bg-gray-800 p-8 rounded-md shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm mb-2" htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="w-full p-2 border border-gray-700 rounded-md bg-gray-700 text-white"
              placeholder="Your Name"
              required
            />
          </div>
          <div>
            <label className="block text-sm mb-2" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="w-full p-2 border border-gray-700 rounded-md bg-gray-700 text-white"
              placeholder="Your Email"
              required
            />
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm mb-2" htmlFor="message">Message</label>
          <textarea
            id="message"
            className="w-full p-2 border border-gray-700 rounded-md bg-gray-700 text-white"
            placeholder="Your Message"
            rows="4"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-fit font-semibold mt-6 px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all"
s          >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default ContactSection;
