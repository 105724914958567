import React from "react";
import ServiceHero from "../component/ServiceHero";
import Sdlc from "../component/Sdlc";
import Technologies from "../component/Technologies";
import ContactHero from "../component/ContactHero";
import { Website } from "../component/Helper";
import Ourwork from "../component/Ourwork";
const SoftwareDevelopment = () => {
  return (
    <div>
      <ServiceHero
        backgroundImage="../data-tracking-bg.png"
        heading="Software Development"
      />
      <div className="max-w-6xl mx-auto my-12 px-6">
        <h2 className="text-3xl text-center mt-8 font-bold text-gray-900">Software Development Services</h2>
        <h4 className="text-xl text-center mb-8 text-blue-600">
          Transform your business with our custom software development services, designed to boost efficiency, enhance scalability, and drive innovation.
        </h4>

        <p className="text-base mb-6 text-gray-700">
          <strong className="text-blue-600">Comprehensive, End-to-End Software Development Solutions:</strong> From ideation to deployment, we provide tailored software development services that align with your business goals. Our solutions are built to improve operational efficiency, deliver a seamless user experience, and support your growth objectives.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-900">Why Choose Our Software Development Services?</h2>

        <h3 className="text-xl font-semibold mb-2">Software Consulting Services</h3>
        <p className="text-base mb-6 text-gray-700">
          Partner with our experienced <strong className="text-blue-600">software consultants</strong> to identify opportunities for digital transformation. We guide you in selecting the right technologies and frameworks to ensure your software solutions align with your strategic vision.
        </p>

        <h3 className="text-xl font-semibold mb-2">Custom Software Development Services</h3>
        <p className="text-base mb-6 text-gray-700">
          Every business is unique, and we build <strong className="text-blue-600">custom software applications</strong> tailored to your specific needs. Whether you're creating a <strong className="text-blue-600">CRM system</strong>, enhancing your e-commerce platform, or developing a robust backend, we ensure your software reflects your brand identity and delivers measurable results.
        </p>

        <h3 className="text-xl font-semibold mb-2">Enterprise Software Development Services</h3>
        <p className="text-base mb-6 text-gray-700">
          Our team specializes in creating <strong className="text-blue-600">enterprise-grade software</strong> that scales with your business. From advanced analytics tools to workflow automation systems, we deliver solutions designed to handle complex operations and drive organizational success.
        </p>

        <h3 className="text-xl font-semibold mb-2">API Development Services</h3>
        <p className="text-base mb-6 text-gray-700">
          Seamlessly connect your systems with our <strong className="text-blue-600">API development services</strong>. We build secure, efficient, and scalable APIs that enable data sharing, integrate third-party tools, and improve interoperability within your digital ecosystem.
        </p>

        <h3 className="text-xl font-semibold mb-2">Maintenance and Support</h3>
        <p className="text-base mb-6 text-gray-700">
          Stay ahead of technical challenges with our ongoing <strong className="text-blue-600">software maintenance</strong> and <strong className="text-blue-600">support services</strong>. From performance optimization to regular updates, we ensure your software remains reliable and up-to-date.
        </p>

        <h3 className="text-xl font-semibold mb-2">Key Features of Our Software Development Process</h3>
        <ul className="list-disc ml-6 text-gray-700">
          <li>
            <strong className="text-blue-600">Agile Development:</strong> We adopt agile methodologies to ensure flexibility and faster time-to-market.
          </li>
          <li>
            <strong className="text-blue-600">User-Centric Design:</strong> Our software solutions prioritize intuitive user experiences to maximize engagement and productivity.
          </li>
          <li>
            <strong className="text-blue-600">Cloud Integration:</strong> Leverage the power of <strong className="text-blue-600">cloud computing</strong> for scalable and secure software solutions.
          </li>
          <li>
            <strong className="text-blue-600">Data Security:</strong> Our solutions are built with robust security protocols to protect your sensitive business data.
          </li>
        </ul>

        <p className="text-base mt-6 text-gray-700">
          With our cutting-edge <strong className="text-blue-600">software development services</strong>, you gain a reliable technology partner dedicated to your success. Let us help you build software that drives innovation, improves efficiency, and delivers lasting value for your business.
        </p>
      </div>


      {/* <Sdlc /> */}
      <Technologies tabs={Website} />
      <ContactHero />
      <Ourwork />
    </div>
  );
};

export default SoftwareDevelopment;
