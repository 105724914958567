import React from "react";
import Marquee from "react-fast-marquee";
import { clientLogos } from "./Helper";
import CounterSection from "./Counter";
import AOS from "aos";
import { useEffect } from "react";

const Ourwork = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration in milliseconds

      once: false, // Animation happens only once
    });
  }, []);

  return (
    <div className="bg-[#e3f0ff]">
      <div className=" min-container p-0 pt-5" >
        <div className=" py-[30px]">
          <h3 className="text-black lg:pt-10 ps-2 text-sm text-center">
            <span className="bg-[#f44e4ee2] text-white px-1 ms-1 font-medium rounded-sm">
              Recent projects
            </span>
          </h3>
          <h2 className=" tracking-tighter lg:text-4xl text-2xl pt-1 text-[#020842] font-semibold text-center">
          Brand We Work With
          </h2>
          <div className=" pt-5 sm:pt-10">
            <Marquee>
              {clientLogos.map((logoSrc, index) => (
                <div
                  key={index}
                  className="flex justify-center items-center w-[200px] h-[100px] mx-[20px]  border rounded-lg"
                >
                  <img
                    alt={`client-logo-${index}`}
                    src={logoSrc}
                    className="h-full object-contain"
                    data-aos="zoom-in"

                  />
                </div>
              ))}
            </Marquee>
          </div>
        </div>

        {/* ///////////////////////Section 3\\\\\\\\\\\\\\\\\\\\ */}
        <div className=" lg:py-10" >
          <div className="flex h-100  justify-between py-10">
            <CounterSection/>
            <div className="hide-below-1024 w-2/6 ps-10" >
              <img
                src="./codecups software company.png"
                alt="chinesman"
                className="h-fit rounded-lg "
                 data-aos="fade-left"
               />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourwork;
