import React from "react";
import ServiceHero from "../component/ServiceHero";
import Sdlc from "../component/Sdlc";
import Technologies from "../component/Technologies";
import ContactHero from "../component/ContactHero";
import { Website } from "../component/Helper";
import Ourwork from "../component/Ourwork";
const AppDevelopment = () => {
  return (
    <div>
      <ServiceHero
        backgroundImage="../data-tracking-bg.png"
        heading="App Development"
      />
    <div className="max-w-6xl mx-auto my-12 px-6">
  <h2 className="text-3xl text-center mt-8 font-bold text-gray-900">
    App Development Services
  </h2>
  <h4 className="text-xl text-center mb-8 text-blue-600">
    Innovative Mobile and Web Apps Tailored to Transform Your Business
  </h4>

  <p className="text-base mb-6 text-gray-700">
    At <strong className="text-blue-600">Code Cups</strong>, we specialize in delivering high-performance, custom <strong className="text-blue-600">app development solutions</strong> designed to enhance user engagement, improve business efficiency, and drive growth. Our comprehensive <strong className="text-blue-600">mobile app development</strong> and <strong className="text-blue-600">web application development</strong> services ensure seamless integration, intuitive design, and scalable solutions. Whether you need an app for <strong className="text-blue-600">iOS</strong>, <strong className="text-blue-600">Android</strong>, or a <strong className="text-blue-600">cross-platform</strong> solution, our team crafts apps tailored to meet your unique business needs and exceed user expectations.
  </p>

  <h2 className="text-2xl font-semibold mb-4 text-gray-900">
    Our App Development Services:
  </h2>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">
    Custom Mobile App Development
  </h3>
  <p className="text-base mb-6 text-gray-700">
    Build high-quality, feature-rich apps for <strong className="text-blue-600">iOS</strong>, <strong className="text-blue-600">Android</strong>, and <strong className="text-blue-600">cross-platform environments</strong>. Our apps are optimized for top-notch performance, interactive user interfaces, and smooth navigation, ensuring maximum user satisfaction.
  </p>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">UI/UX Design Services</h3>
  <p className="text-base mb-6 text-gray-700">
    Elevate your app with visually appealing and user-friendly interfaces. We prioritize <strong className="text-blue-600">intuitive navigation</strong>, responsive layouts, and modern designs to create a memorable user experience.
  </p>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">Web Application Development</h3>
  <p className="text-base mb-6 text-gray-700">
    Develop fast, secure, and scalable <strong className="text-blue-600">web applications</strong> that deliver robust functionality and exceptional user experiences. Our web solutions are tailored to support your business growth and enhance customer engagement.
  </p>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">
    Cross-Platform App Development
  </h3>
  <p className="text-base mb-6 text-gray-700">
    Save time and resources with our <strong className="text-blue-600">cross-platform development</strong> services using frameworks like <strong className="text-blue-600">React Native</strong> and <strong className="text-blue-600">Flutter</strong>. Our apps perform seamlessly on both <strong className="text-blue-600">iOS</strong> and <strong className="text-blue-600">Android</strong> devices, ensuring consistent functionality across platforms.
  </p>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">
    App Integration and API Development
  </h3>
  <p className="text-base mb-6 text-gray-700">
    Enhance your app’s capabilities with custom <strong className="text-blue-600">API integration</strong>. We integrate third-party services and APIs to ensure seamless communication between your app and existing systems for a unified user experience.
  </p>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">
    Quality Assurance and Testing
  </h3>
  <p className="text-base mb-6 text-gray-700">
    Our team conducts thorough <strong className="text-blue-600">app testing</strong>, including functionality, usability, and security checks across multiple devices. This ensures your app performs flawlessly and remains secure.
  </p>

  <h3 className="text-xl font-semibold mb-2 text-blue-600">
    App Maintenance and Support
  </h3>
  <p className="text-base mb-6 text-gray-700">
    Keep your app updated and running smoothly with our ongoing <strong className="text-blue-600">maintenance</strong> and <strong className="text-blue-600">support services</strong>. We provide updates, troubleshoot issues, and ensure your app stays relevant to meet changing market demands.
  </p>

  <p className="text-base text-gray-700">
    Partner with <strong className="text-blue-600">Code Cups</strong> for innovative <strong className="text-blue-600">app development solutions</strong> that align with your business goals, captivate your users, and achieve measurable success in today's digital landscape.
  </p>
</div>


      {/* <Sdlc /> */}
      <Technologies tabs={Website} />
      <ContactHero />
      <Ourwork/>
    </div>
  );
};

export default AppDevelopment;
