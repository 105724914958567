// AboutUs.js

import React from "react";
import Ourwork from "../component/Ourwork";
import ContactHero from "../component/ContactHero";
import ServiceHero from "../component/ServiceHero";
import { Link } from "react-router-dom";
import ContactSection from "../component/ContactSection";
import { useEffect } from "react";
import AOS from "aos";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration in milliseconds

      once: false, // Animation happens only once
    });
  }, []);

  return (
    <div className="overflow-x-hidden">
      <ServiceHero
        backgroundImage="../bg-banner2.png"
        heading="About Us"
      />
       {/* <section
        className="relative bg-cover bg-center h-[300px]"
        style={{ backgroundImage: `url(${"../bg-banner2.png"})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent flex items-center justify-center">
          <div
            className="text-center text-white"
            data-aos="fade-down"
            data-aos-delay="200"
          >
            <h1 className="text-5xl font-extrabold tracking-wide mb-4">
            About Us
            </h1>
            <p className="text-lg font-medium opacity-80">{subheading}</p> 
          </div>
        </div>
      </section> */}
      <div className="bg-white py-10">
        <div className="grid  grid-cols-1 lg:grid-cols-2  lg:gap-10 px-3 md:px-8">
          <div className=" relative">
            <div className="absolute top-10 left-[5%]">
              <img
                className="world_animate  md:w-[80px] w-[50px] "
                src="./world.webp"
                alt="world"
              />
            </div>
            <img
              className=" ml-[-20px] md:ml-0"
              src="./Section4homepage.webp"
              alt="Section4homepage"
              data-aos="fade-up"
            />
          </div>
          <div className="lg:ps-10 pt-4">
            {/* <h3 className="text-black ps-2 text-sm" data-aos="fade-left">
                        We are 😃
                        <span className="bg-[#f44e4ee2] text-white px-1 font-medium rounded-sm">
                          CodeCups
                        </span>
                      </h3> */}
            <h2
              className=" tracking-tighter lg:text-4xl text-2xl pt-1 text-[#020842] font-semibold"
              data-aos="fade-left"
            >
              Our Commitment to Client Satisfaction
            </h2>

            <p className=" text-[#383838e6] lg:pe-10 md:pt-5 pt-3 md:text-md text-md font-semibold">
              At CodeCups, our commitment to client satisfaction is at the core
              of everything we do. We understand clients' success.
            </p>
            {/* <ul className=" ps-5 pt-5 mb-6">
              <li className="list-disc text-[#383838e6] text-sm md:text-md font-medium ">
                Grow your business the right way.
              </li>
              <li className="list-disc py-2 sm:py-3 text-[#383838e6] text-sm md:text-md font-medium ">
                Let business growth help your business grow.
              </li>
              <li className="list-disc text-[#383838e6] text-sm md:text-md font-medium ">
                Helping you to get better.
              </li>
            </ul> */}
            <p className="text-gray-600">
              Codecups started with a vision to revolutionize the digital landscape. Founded in 2022, our journey began with a small, dedicated team passionate about software solutions and digital marketing. Over the years, we have evolved and expanded our services to include website development, UI/UX design, graphics designing, SEO, SMO, and comprehensive digital marketing strategies.

              From our humble beginnings, we've grown into a trusted partner for businesses seeking innovative and tailored solutions. Our commitment to excellence and customer satisfaction has driven us to continuously innovate and adapt to the ever-changing digital world. Today, Codecups stands as a beacon of quality and reliability in the digital realm, helping brands connect with their audience and achieve their full potential.
            </p>
            <div className="w-fit mt-6 px-8 py-3 font-semibold bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all">
              <Link to="/contact" data-aos="fade-right">
                Work With Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-blue-50 py-12 px-8 md:px-16">
        <div className="grid md:grid-cols-2 gap-8">
          {/* History Card */}
          {/* <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="flex justify-center mb-4">
              <span className="text-blue-500 text-4xl">📜</span>
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">
              Our History
            </h3>
          
          </div> */}

          {/* Mission Card */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="flex justify-center mb-4">
              <span className="text-orange-500 text-4xl">🎯</span>
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">
              Our Mission
            </h3>
            <p className="text-gray-600">
              <b>Empowering Digital Excellence </b>

              At Codecups, our mission is to empower businesses with state-of-the-art digital marketing software and applications. We aim to simplify processes, boost productivity, and drive measurable growth.
              <br />
              We strive to provide customized solutions that help brands connect with their audience and achieve their full potential in the digital landscape. Partner with Codecups to transform your business and reach new heights of success.
            </p>
          </div>

          {/* Vision Card */}
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <div className="flex justify-center mb-4">
              <span className="text-pink-500 text-4xl">👁️</span>
            </div>
            <h3 className="text-xl font-bold text-gray-800 mb-2">Our Vision</h3>
            <p className="text-gray-600">
              At Codecups, we envision a world where businesses thrive in the digital era through innovative solutions. Our commitment is to drive digital transformation by delivering top-notch software, marketing applications, and design services. We aim to empower brands to reach their full potential, fostering meaningful connections with their audience, and achieving sustained growth in a rapidly evolving digital landscape.
            </p>
          </div>
        </div>
      </section>
      <section className="flex flex-col md:flex-row items-center bg-blue-50 p-8 md:p-16 rounded-lg">
        {/* Left Side - Image/Illustration */}
        <div className="md:w-1/2 flex justify-center mb-8 md:mb-0 p-8" data-aos="fade-right">
          <img
            src="../about.webp"
            alt="Team Presentation"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Right Side - Content */}
        <div className="md:w-1/2 space-y-4" >
          <div className="bg-[#f44e4ee2] text-white px-1 ms-1 font-medium rounded-sm w-fit">Why Us</div>
          <h2 className="tracking-tighter lg:text-4xl text-2xl pt-1 text-[#020842] font-semibold" data-aos="fade-left">
            Why Our Services are Better Than Others?
          </h2>

          {/* Benefits List */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">✅</span>
              <p className="font-semibold text-gray-700">Quality Comes First</p>
            </div>



            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">📦</span>
              <p className="font-semibold text-gray-700">On-time Delivery</p>
            </div>

            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">🧾</span>
              <p className="font-semibold text-gray-700">Transparent Costs</p>
            </div>

            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">💻</span>
              <p className="font-semibold text-gray-700">
                Qualified Developers
              </p>
            </div>

            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">🚀</span>
              <p className="font-semibold text-gray-700">Quick Scale-up</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">🎯</span>
              <p className="font-semibold text-gray-700">Tailored Solutions</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">⚙️</span>
              <p className="font-semibold text-gray-700">Cutting-Edge Technology</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">📊</span>
              <p className="font-semibold text-gray-700">Data-Driven Approach</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">✨</span>
              <p className="font-semibold text-gray-700">User-Friendly Interface</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">🔗</span>
              <p className="font-semibold text-gray-700">All-in-One Platform</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">💰</span>
              <p className="font-semibold text-gray-700">Cost-Effective Services</p>
            </div>

            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">🏆</span>
              <p className="font-semibold text-gray-700">Proven Results</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">🚀</span>
              <p className="font-semibold text-gray-700">Faster Implementation</p>
            </div>
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow" data-aos="fade-left">
              <span className="text-blue-500 text-xl">📈</span>
              <p className="font-semibold text-gray-700">Scalable Solutions</p>
            </div>

          </div>
        </div>
      </section>
      <Ourwork />
      <ContactHero />
      {/* <ContactSection/> */}
    </div>
  );
};

export default About;
