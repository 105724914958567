import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Field = ({ 
  headerImage, 
  heading, 
  subheading, 
  content, 
  cards, 
  workItems, 
  contactTitle, 
  contactSubtitle 
}) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="font-[Poppins]">
      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center h-[600px]"
        style={{ backgroundImage: `url(${headerImage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent flex items-center justify-center">
          <div
            className="text-center text-white"
            data-aos="fade-down"
            data-aos-delay="200"
          >
            <h1 className="text-5xl font-extrabold tracking-wide mb-4">
              {heading}
            </h1>
            <p className="text-lg font-medium opacity-80">{subheading}</p>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6 lg:px-12 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div data-aos="fade-right">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">
              {content.title}
            </h2>
            <p className="text-gray-600 leading-relaxed text-lg">
              {content.text}
            </p>
            <button className="mt-6 px-8 py-3 bg-gradient-to-r from-blue-500 to-teal-400 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all">
              Learn More
            </button>
          </div>
          <div data-aos="fade-left">
            <img
              src={content.image}
              alt={content.title}
              className="rounded-lg shadow-lg hover:scale-105 transition-transform duration-500"
            />
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-gradient-to-br from-blue-50 to-gray-100">
        <div className="container mx-auto px-6 lg:px-12 text-center">
          <h2
            className="text-4xl font-bold text-gray-800 mb-10"
            data-aos="zoom-in"
          >
            Services
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {cards.map((card, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-transform duration-500"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <h3 className="text-lg font-semibold mb-3">{card.title}</h3>
                <p className="text-sm text-gray-600">{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Work Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-6 lg:px-12 text-center">
          <h2
            className="text-4xl font-bold text-gray-800 mb-10"
            data-aos="zoom-in"
          >
            Our Work
          </h2>
          <div className="grid grid-cols-3 md:grid-cols-6 gap-4 items-center">
            {workItems.map((item, index) => (
              <div
                key={index}
                className="hover:scale-105 transition-transform duration-300"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <img
                  src={item.logo}
                  alt={item.name}
                  className="mx-auto rounded-lg"
                />
                <p className="mt-2 text-sm text-gray-500">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-gradient-to-r from-blue-600 to-teal-500 text-white">
        <div className="container mx-auto px-6 lg:px-12">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center">
            <div data-aos="fade-right">
              <h2 className="text-4xl font-bold">{contactTitle}</h2>
              <p className="mt-4 opacity-90">{contactSubtitle}</p>
            </div>
            <form
              className="mt-8 md:mt-0"
              data-aos="fade-left"
            >
              <input
                type="text"
                placeholder="Name"
                className="w-full px-4 py-3 mb-4 rounded bg-white text-gray-800"
              />
              <input
                type="email"
                placeholder="Email"
                className="w-full px-4 py-3 mb-4 rounded bg-white text-gray-800"
              />
              <textarea
                placeholder="Message"
                className="w-full px-4 py-3 mb-4 rounded bg-white text-gray-800"
                rows="4"
              ></textarea>
              <button className="px-6 py-3 bg-gradient-to-r from-teal-400 to-blue-500 rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Field;
