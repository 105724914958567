import React from "react";
import ServiceHero from "../component/ServiceHero";
import Sdlc from "../component/Sdlc";
import Technologies from "../component/Technologies";
import ContactHero from "../component/ContactHero";
import { Website } from "../component/Helper";
import Ourwork from "../component/Ourwork";
const WebsiteDevelopment = () => {
  return (
    <div>
      <ServiceHero
        backgroundImage="../website-dev-banner.png"
        heading="Website Development"
      />
      <div className="max-w-6xl mx-auto my-12 px-6 ">
        <h1 class="text-4xl font-bold text-center text-blue-600 mb-6">Website Development Services at Codecups</h1>
        <h2 class="text-2xl font-semibold text-gray-700 mb-4 text-center">Your Trusted Website Development Agency Near You</h2>
        <p class="text-lg text-gray-600 leading-relaxed mb-6 text-center">
          Looking for a reliable website development agency to bring your ideas to life? At Codecups, we specialize in crafting websites that reflect your brand, engage users, and drive results. Whether you need a <span class="font-semibold text-blue-500">WordPress developer</span>, a <span class="font-semibold text-blue-500">Shopify agency</span>, or a team for your <span class="font-semibold text-blue-500">React projects</span>, Codecups has the expertise to turn your vision into a powerful digital experience.
        </p>

        <h3 class="text-xl font-bold text-gray-800 mb-4">Why Choose Codecups for Website Development?</h3>
        <p class="text-lg text-gray-600 mb-6">
          We are more than just a <span class="font-semibold text-blue-500">website development agency near you</span>; we are your growth partner. From design to deployment, our services are tailored to deliver websites that stand out, rank well, and convert visitors into customers.
        </p>

        <h3 class="text-xl font-bold text-gray-800 mb-4">Our Expertise</h3>
        <ul class="list-disc list-inside space-y-4 mb-6">
          <li>
            <strong class="text-blue-500">Custom UI/UX Design</strong>:
            Create stunning designs that captivate users and enhance engagement. Deliver seamless user experiences with intuitive navigation and interfaces.
          </li>
          <li>
            <strong class="text-blue-500">WordPress Development</strong>:
            Build responsive WordPress websites that are easy to manage and SEO-friendly. Customize themes and plugins to align perfectly with your business goals.
          </li>
          <li>
            <strong class="text-blue-500">Shopify Development</strong>:
            Develop feature-rich Shopify stores optimized for sales and scalability. Integrate secure payment gateways, advanced analytics, and inventory management systems.
          </li>
          <li>
            <strong class="text-blue-500">React and React Native Development</strong>:
            Craft fast and dynamic web and mobile applications with React. Ensure a smooth, responsive user experience across all devices.
          </li>
          <li>
            <strong class="text-blue-500">eCommerce Solutions</strong>:
            Launch your online store with tailored eCommerce solutions for any platform. Offer your customers a secure, easy-to-navigate shopping experience that drives conversions.
          </li>
        </ul>

        <h3 class="text-xl font-bold text-gray-800 mb-4">Why Codecups Stands Out</h3>
        <ul class="list-disc list-inside space-y-4">
          <li>
            Expertise in website development services for businesses of all sizes.
          </li>
          <li>
            Proven track record as a trusted <span class="font-semibold text-blue-500">Shopify developer agency</span> and <span class="font-semibold text-blue-500">React project agency</span>.
          </li>
          <li>
            Skilled in delivering solutions for local businesses, making us the go-to <span class="font-semibold text-blue-500">website development agency near me</span>.
          </li>
        </ul>
      </div>

      {/* <Sdlc /> */}
      <Technologies tabs={Website} />
      <ContactHero />
      <Ourwork />
    </div>
  );
};

export default WebsiteDevelopment;
