import React from "react";
import ServiceHero from "../component/ServiceHero";
import Sdlc from "../component/Sdlc";
import Technologies from "../component/Technologies";
import ContactHero from "../component/ContactHero";
import { Website } from "../component/Helper";
import Ourwork from "../component/Ourwork";
const CRM = () => {
  return (
    <div className="overflow-x-hidden">
      <ServiceHero
        backgroundImage="../website-dev-banner.png"
        heading=" (CRM) Solutions and Design"
      />
     <div className="max-w-6xl mx-auto my-12 px-6">
  <h2 className="text-3xl text-center mt-8 font-bold">
    Customer Relationship Management (CRM) Solutions and Design
  </h2>
  <h4 className="text-xl text-center mb-8 text-blue-600">
    Enhancing Customer Connections, Boosting Business Success
  </h4>

  <p className="text-base mb-6 text-gray-700">
    At <strong className="text-blue-600">Code Cups</strong>, we design and implement <strong className="text-blue-600">CRM solutions</strong> that redefine customer interactions and streamline your business processes. Our custom <strong className="text-blue-600">CRM systems</strong> are built to centralize data, automate workflows, and empower your team with <strong className="text-blue-600">real-time insights</strong>—ensuring every customer interaction is meaningful, productive, and personalized.
  </p>

  <h2 className="text-2xl font-semibold mb-4">Why Choose Our CRM Solutions?</h2>

  <h3 className="text-xl font-semibold mb-2">Tailored CRM Design</h3>
  <p className="text-base mb-6 text-gray-700">
    Our <strong className="text-blue-600">CRM solutions</strong> are uniquely designed to reflect your business's identity and specific operational needs, whether you’re managing <strong className="text-blue-600">sales pipelines</strong>, <strong className="text-blue-600">customer support</strong>, or <strong className="text-blue-600">marketing automation</strong>.
  </p>

  <h3 className="text-xl font-semibold mb-2">Data-Driven Insights</h3>
  <p className="text-base mb-6 text-gray-700">
    Access detailed <strong className="text-blue-600">analytics</strong> and <strong className="text-blue-600">reporting capabilities</strong> to gain valuable insights into <strong className="text-blue-600">customer behavior</strong> and <strong className="text-blue-600">business performance</strong>. With intuitive dashboards, your team can make informed decisions quickly.
  </p>

  <h3 className="text-xl font-semibold mb-2">Seamless Integration</h3>
  <p className="text-base mb-6 text-gray-700">
    We ensure smooth <strong className="text-blue-600">integration</strong> with your existing systems, including <strong className="text-blue-600">ERP</strong>, email, marketing, and social media platforms, to create a unified experience across your entire tech ecosystem.
  </p>

  <h3 className="text-xl font-semibold mb-2">Enhanced Customer Experience</h3>
  <p className="text-base mb-6 text-gray-700">
    From streamlined communication to personalized service, our <strong className="text-blue-600">CRM solutions</strong> are built to foster <strong className="text-blue-600">customer loyalty</strong> and long-term satisfaction by giving you a <strong className="text-blue-600">360-degree view</strong> of each customer.
  </p>

  <h3 className="text-xl font-semibold mb-2">Automation and Efficiency</h3>
  <p className="text-base mb-6 text-gray-700">
    Automate repetitive tasks, from <strong className="text-blue-600">follow-ups</strong> to <strong className="text-blue-600">data entry</strong>, freeing up your team to focus on building relationships and closing deals.
  </p>

  <p className="text-base text-gray-700">
    With <strong className="text-blue-600">Code Cups’ CRM Solutions and Design</strong>, you’re equipped with a powerful tool to nurture relationships, drive sales, and achieve your growth goals.
  </p>
</div>

      {/* <Sdlc /> */}
      <Technologies tabs={Website}/>
      <ContactHero />
      <Ourwork/>
    </div>
  );
};

export default CRM;
