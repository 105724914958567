// App.jsx
import React from "react";
import Field from "../component/Fields";
import ClientCard from "../component/ClientCard";
import Ourwork from "../component/Ourwork";

const data = {
  headerImage: "header-image.jpg",
  heading: "What is a Healthcare?",
  subheading: "Fearless, relentless, no-holds-barred marketing...",
  content: {
    title: "Be a Mavrik",
    text: "What does it mean to be a Mavrik? To us, it means fearless...",
    image: "content-image.jpg",
  },
  cards: [
    { title: "Creative Development", description: "Description 1" },
    { title: "Brand Identity", description: "Description 2" },
    { title: "Media Planning", description: "Description 3" },
    { title: "Web Development", description: "Description 4" },
  ],
  workItems: [
    { name: "Company 1", logo: "logo1.png" },
    { name: "Company 2", logo: "logo2.png" },
  ],
  contactTitle: "Let's Chat Over a Brew",
  contactSubtitle: "Coffee, tea, or something stronger...",
};

function HealthCare() {
    return (
        <div>
          <Field {...data} />
          <Ourwork/>

        </div>
      )

  
}

export default HealthCare;

