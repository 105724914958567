import React from 'react';

const ServiceHero = ({ backgroundImage, heading }) => {
  return (
    <section
      className="relative bg-cover bg-center item-center h-[200px] lg:h-[400px] lg:mt-0 "
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent flex items-center justify-center">
        <div
          className="text-center text-white"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <h1 className="text-5xl font-extrabold tracking-wide">
            {heading}            </h1>
          {/* <p className="text-lg font-medium opacity-80">{subheading}</p> */}
        </div>
      </div>
    </section>
  );
};

export default ServiceHero;
