export const boxes = [
  { id: 1, content: "IT Management Services", imageUrl: "../it.webp", url:"/it-management"},
  { id: 2, content: "Data Tracking and Security", imageUrl: "../data-tracking-service.png" ,url:"/data-tracking"},
  { id: 3, content: "Website Development", imageUrl: "../websiteservice.png", url:"/website-development"},
  { id: 4, content: "(CRM) Solutions and Design", imageUrl: "../mts.webp" ,url:"/crm-Solution"},
  { id: 5, content: "Software Development", imageUrl: "../uiux.webp" ,url:"/software-development"},
];
export const clientLogos = [
  "./client/1.png",
  "./client/2.png",
  "./client/3.png",
  "./client/4.png",
  "./client/5.png",
  "./client/6.png",
  "./client/7.png",
  "./client/8.png",
 
];

export const Website = [
  {
    name: "Web Platform",
    content: "We use modern front-end technologies like React, Vue, and Angular.",
    logos: ["../website_technology/icon_c_plus.svg", "../website_technology/icon_elephent.svg", "../website_technology/icon_java.svg",
      "../website_technology/icon_javascript.svg", "../website_technology/icon_laravel.svg", "../website_technology/icon_php..svg",
      "../website_technology/icon_python.svg", "../website_technology/icon_react_js.svg", "../website_technology/icon_ruby.svg",
      "../website_technology/icon_swift.svg", "../website_technology/icon_typescript.svg", 

    ],
  },
  {
    name: "Backend Technology",
    content: "Our backend stack includes Node.js, Django, and more.",
    logos: ["../website_technology/icon_java.svg", "../website_technology/1 (1).png", "../website_technology/icon_ruby.svg", "../website_technology/1 (2).png"],
  },
  {
    name: "Database",
    content: "We specialize in MySQL, MongoDB, PostgreSQL, and more.",
    logos: ["../website_technology/1 (5).png", "../website_technology/1 (6).png", "../website_technology/1 (1).webp"],
  },
  {
    name: "Clouds",
    content: "AWS, Azure, and Google Cloud are some of our cloud platforms.",
    logos: ["../website_technology/1 (1).webp", "../website_technology/1 (2).jpg"],
  },
  {
    name: "Mobile App",
    content: "We develop mobile applications using React Native and Flutter.",
    logos: ["../website_technology/icon_react_js.svg", "../website_technology/1 (4).png"],
  },
  {
    name: "Framework",
    content: "We adopt frameworks that suit project requirements for efficiency.",
    logos: ["../website_technology/icon_react_js.svg", "../website_technology/1 (3).png"],
  },
];
